import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const FormProviderContext = createContext("");

function FormProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  // Check for form parameter on mount and URL changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const formParam = searchParams.get("form");
    if (formParam && formParam === "open") {
      setOpen(true);
    }
  }, [location.search]);

  const handleOpenForm = () => {
    // Add query parameter to current URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("form", "open");

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    setOpen(true);
  };

  const handleCloseForm = () => {
    // Remove query parameter
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("form");

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    setOpen(false);
  };

  return (
    <FormProviderContext.Provider
      value={{ open, handleOpenForm, handleCloseForm }}>
      {children}
    </FormProviderContext.Provider>
  );
}

export function useFormProvider() {
  return useContext(FormProviderContext);
}

export default FormProvider;
