import React from "react";
import Container from "../Container";
import { Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CASESTUDY_COVER_IMAGE_1 } from "../../utils/constants";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container
      wrapperStyle={{
        marginTop: isXs ? 72.5 : 76.5,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${CASESTUDY_COVER_IMAGE_1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: "120px 0",
        minHeight: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom sx={{ color: "white" }}>
          Transforming Operations at a Leading Fertilizer Plant
        </Typography>
        <Typography variant="h6" sx={{ color: "rgba(255, 255, 255, 0.9)" }}>
          How Raven helped streamline operations and enhance safety at a 3M tonne/year facility
        </Typography>
      </Box>
    </Container>
  );
}

export default Hero;
