import React from "react";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import Container from "../Container";
import Logo from "../Logo";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { links } from "./Navbar";

const useStyles = makeStyles()((theme) => ({
  footerItem: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
}));

function Footer() {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container>
      <Grid container rowSpacing={5} columnSpacing={5}>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Link component={NavLink} to={""} underline="none">
              <Logo />
            </Link>
            <Box mb={1} />
            <Typography variant="subtitle2">© Raven.dev, Inc. 2025</Typography>
            <Box mb={1} />
            <Typography variant="subtitle2">Based in San Francisco & Bengaluru</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Backed by</Typography>
            <img src="/logo/yc_logo.svg" alt="yc" width={200} height={80} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Resources</Typography>
            <Box mb={1} />
            {links.map((link) => {
              const { key, label, path, type } = link;
              return (
                <Link
                  color={"secondary"}
                  key={key}
                  component={NavLink}
                  to={path}
                  target={type === "external" ? "_blank" : undefined}
                  sx={{
                    display: "block",
                    marginBottom: 1,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {label}
                </Link>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2">Contact us at</Typography>
            <Box mb={1} />
            <Link
              color={"secondary"}
              href="mailto:priyansh@startraven.com"
              sx={{
                display: "block",
                mb: 1,
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              priyansh@startraven.com
            </Link>
            <Link
              color={"secondary"}
              href="tel:+919945268531"
              sx={{
                display: "block",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              +91 99452 68531
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
